import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

import { firebaseConfig } from 'config/constants';
import { getFunctions } from "firebase/functions";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app);

export default auth; // Default export
export { functions }; // Named export
