import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import Nft1 from "assets/img/nfts/Nft1.png";
import HistoryItem from "views/admin/marketplace/components/HistoryItem";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
      <HistoryItem
              name='Fit Group USA'
              image={Nft1}
            />
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
