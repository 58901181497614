// RefreshContext.js
import React, { createContext, useContext, useState } from 'react';

const RefreshContext = createContext();

export const useRefresh = () => useContext(RefreshContext);

export const RefreshProvider = ({ children }) => {
  const [refreshKey, setRefreshKey] = useState(0);
  const [colorMode, setColorMode] = useState('light'); // Assuming initial state is 'light'

  const refresh = () => {
    setRefreshKey(prevKey => prevKey + 1);
  };

  const toggleColorMode = () => {
    setColorMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <RefreshContext.Provider value={{ refresh, toggleColorMode, colorMode }}>
      {children}
    </RefreshContext.Provider>
  );
};
