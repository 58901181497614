import { useEffect } from 'react';

const TabVisibilityDetector = ({ onVisibilityChange }) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        onVisibilityChange(true);
      } else {
        onVisibilityChange(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [onVisibilityChange]);

  return null;
};

export default TabVisibilityDetector;