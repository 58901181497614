import React from 'react';
// Firebase auth functions
import {
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from 'firebase/auth';
// Firebase auth instance
import firebaseAuth from 'lib/firebase';
// Google oauth provider
const provider = new GoogleAuthProvider();
// Contexts
export const AuthContext = React.createContext(null);

export const ContextProvider = (props) => {
  // States to check auth status
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    // Listener updates auth status when detects change
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        setIsSignedIn(true);
        setUser(user);
      } else {
        setIsSignedIn(false);
        setUser(null);
      }
    });
  }, []);
  // Functions handling auth
  const getFriendlyErrorMessage = (errorCode) => {
    const errorMessages = {
      'auth/invalid-email': 'The email address is not valid.',
      'auth/user-disabled': 'The user account has been disabled.',
      'auth/user-not-found': 'No user found with this email.',
      'auth/wrong-password': 'Incorrect password. Please try again.',
      'auth/too-many-requests': 'Too many attempts. Please try again later.',
      'auth/network-request-failed': 'Network error. Please check your connection.',
      'auth/email-already-in-use': 'This email is already in use.',
      'auth/weak-password': 'The password is too weak.',
      'auth/invalid-login-credentials': 'The email and password combination you entered is incorrect. Please try again.',
      // Add more error codes and messages as needed
    };
  
    return errorMessages[errorCode] || 'An unknown error occurred. Please try again.';
  };
  
  const signIn = async (email, password) => {
    try {
      await signInWithEmailAndPassword(firebaseAuth, email, password);
      return;
    } catch (err) {
      console.log(err.message);
      return getFriendlyErrorMessage(err.code);
    }
  };
  const signUp = async (email, password) => {
    try {
      await createUserWithEmailAndPassword(firebaseAuth, email, password);
      return;
    } catch (err) {
      console.log(err.message);
      return getFriendlyErrorMessage(err.code);
    }
  };
  const signOut = async () => {
    try {
      await firebaseSignOut(firebaseAuth);
    } catch (err) {
      console.log(err.message);
    }
  };
  const forgotPassword = async (email) => {
    try {
      await sendPasswordResetEmail(firebaseAuth, email);
    } catch (err) {
      console.log(err.message);
    }
  };
  const googleSignIn = async () => {
    try {
      await signInWithPopup(firebaseAuth, provider);
    } catch (err) {
      console.log(err.message);
    }
  };
  // Context provider
  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        user,
        signIn,
        signUp,
        signOut,
        googleSignIn,
        forgotPassword,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
