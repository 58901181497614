/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import InstructorRetoolEmbed from "views/admin/default/components/InstructorRetoolEmbed";

// Contexts
import { AuthContext } from 'contexts/AuthContext';

// Assets
import React, { useContext } from "react";

export default function Overview() {
  const { user } = useContext(AuthContext);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <InstructorRetoolEmbed
        user = {user}
        currentRoute = {'calendar'}
      />
    </Box>
  );
}
